import auth from './auth';

function showErrors(error, formName) {
  const { status } = error.response;
  const { data } = error.response;
  if (status === 422) {
    this.$refs[formName].setErrors(data.errors);
  }
}

const imageDefaultPath = process.env.VUE_APP_URL_IMAGE_DEFAULT;

const gutterDefault = 20;

const showFormatDate = 'dd-MM-yyyy';

const showMonthFormatDate = 'MM-yyyy';

const valueFormatDate = 'yyyy-MM-dd';

const moneyFormat = {
  decimal: '.',
  thousands: ',',
  prefix: '$ ',
  precision: 2,
  masked: false,
};
const baseURL = process.env.VUE_APP_API_URL_BASE;

const _token = auth.getToken();

const onImageupload = (blobInfo, success, failure, progress) => {
  var xhr, formData;
  const path = 'dashboard/images/upload';

  xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.open('POST', `${baseURL}${path}`);
  xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
  xhr.setRequestHeader('Authorization', _token);

  xhr.upload.onprogress = function (e) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function () {
    var json;

    if (xhr.status === 403) {
      failure('HTTP Error: ' + xhr.status, { remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure('HTTP Error: ' + xhr.status);
      return;
    }

    json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      failure('Invalid JSON: ' + xhr.responseText);
      return;
    }

    success(json.location);
  };

  xhr.onerror = function () {
    failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };

  formData = new FormData();
  formData.append('image', blobInfo.blob(), blobInfo.filename());
  // formData.append('_token', _token);

  xhr.send(formData);
};

const saleStatusIds = Object.freeze({
  libre: 1,
  reservado: 2,
  reservadoC: 3,
  reservadoL: 4,
  anticipo: 5,
  porDesembolsar: 6,
  activadoMaf: 7,
  facturado: 8,
  cancelado: 9,
  canceladoPt: 10,
  canceladoTestDrive: 11,
  canceladoOtroMes: 12,
  canceladoAutonort: 13,
  testDrive: 14,
  kinto: 15,
  activo: 16,
  bloqueado: 17,
  standBy: 18,
  devolucion: 20,
  devolucionEjecutada: 21,
});

const cancelStatusIds = Object.freeze([
  saleStatusIds.cancelado,
  saleStatusIds.canceladoPt,
  saleStatusIds.canceladoTestDrive,
  saleStatusIds.canceladoOtroMes,
  saleStatusIds.canceladoAutonort,
]);

const cantEditOcvStatusIds = Object.freeze([
  saleStatusIds.porDesembolsar,
  saleStatusIds.facturado,
  saleStatusIds.cancelado,
  saleStatusIds.canceladoPt,
  saleStatusIds.canceladoTestDrive,
  saleStatusIds.canceladoOtroMes,
  saleStatusIds.canceladoAutonort,
]);

export {
  showErrors,
  imageDefaultPath,
  gutterDefault,
  showFormatDate,
  showMonthFormatDate,
  valueFormatDate,
  onImageupload,
  moneyFormat,
  saleStatusIds,
  cancelStatusIds,
  cantEditOcvStatusIds,
};

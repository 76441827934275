<template>
  <div class="wrapper__container">
    <SidebarComponent />
    <TopbarComponent />
    <router-view></router-view>
    <footer>
      <span
        >COPYRIGHT &copy; {{ year }} GEXUS SAC, Todos los derechos
        reservados</span
      >
      <span>Hecho con amor <i class="fa-regular fa-heart"></i></span>
    </footer>
    <div
      class="bg-blur"
      @click="onCloseMenu()"
      @keydown.esc="onCloseMenu()"
    ></div>
    <ModalChangePassword></ModalChangePassword>
    <ModalELearning></ModalELearning>
  </div>
</template>

<script>
import SidebarComponent from '@/components/Sidebar.vue';
import TopbarComponent from '@/components/Topbar.vue';
import ModalChangePassword from '@/components/ChangePassword.vue';
import ModalELearning from '@/components/ModalELearning.vue';

export default {
  name: 'DashboardLayout',
  components: {
    SidebarComponent,
    TopbarComponent,
    ModalChangePassword,
    ModalELearning,
  },
  data: () => ({
    year: new Date().getFullYear(),
  }),
  methods: {
    onCloseMenu() {
      this.$store.dispatch('ON_CLOSE_MENU');
    },
  },
};
</script>

<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'RootComponent',
  created() {
    window.addEventListener('resize', this.myEventHandler);
  },
  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
    if (this.user) {
      this.onConnectToSocket();
      this.onConnectToGlobalChannel();
      this.onConnectToRoleChannel();
      this.onConnectToPersonalChannel();
    }
  },
  methods: {
    myEventHandler(e) {
      this.$store.commit('SET_WINDOW_WIDTH', e.target.innerWidth);
    },
  },
};
</script>

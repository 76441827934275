import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  configure,
  localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";

configure({
  useConstraintAttrs: false,
});

localize("es", es);

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("decimal", {
  validate: (value, { decimals = "*", separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );

    return {
      valid: regex.test(value),
    };
  },
  message: "El {_field_} debe contener al menos un número decimal",
});

extend("zero", {
  validate: (value) => value !== 0,
  message: "El valor debe ser diferente de 0",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
